<!--
 * @Description: PC头部菜单
 * @Autor: WangYuan
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 17:09:06
-->
<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <div>
        <span v-if="isBack" @click="toPagesManage">
          <i class="el-icon-arrow-left"></i>
          <span class="f14 pointer">返回</span>
        </span>
        <span class="f14 pointer ml20" @click="back">切换应用</span>
      </div>

      <!-- 右侧内容 -->
      <div>
        <!-- <git-control />
        <el-button size="small" @click="toSchema">schema 生成器</el-button>

        <el-button size="small ml10" @click="viewQr">商城二维码</el-button> -->

        <el-button size="small ml10" icon="el-icon-view" @click="show = true">实时预览</el-button>

        <el-button size="small f-white bg-theme" icon="el-icon-tickets" @click="openSave">保存</el-button>

        <el-button size="small ml10" @click="onLogout">退出</el-button>
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>

    <save-dialog ref="save"></save-dialog>

    <qr-dialog ref="qr"></qr-dialog>
  </div>
</template>

<script>
import RealTimeView from "./RealTimeView.vue";
import SaveDialog from "@/components/SaveDialog";
import QrDialog from "@/components/QrDialog";
import jrQrcode from "jr-qrcode";
import { mapGetters, mapMutations } from "vuex";
import { editProject, pageSave, shopUpdate } from "@/api/project";
import {getProjectDetail} from "@/api/easyApp";


export default {
  components: {
    RealTimeView,
    SaveDialog,
    QrDialog,
  },

  provide() {
    return {
      topBar: this,
      issave:false
    };
  },

  data() {
    return {
      show: false,
      isBack: false
    };
  },

  computed: {
    ...mapGetters(["project", "userInfo", "curPage"]),
  },

  watch: {
    "$route.path": {
      handler() {
        if (this.$route.path == '/mall/page-build') {
          this.isBack = true;
        } else {
          this.isBack = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },

  mounted() {
    console.log("this.issavethis.issavethis.issave:",this.issave);
  },

  methods: {
    ...mapMutations(["logout", "setProject"]),
    // 返回商城管理
    back() {
      this.$router.push({ name: "managet" });
    },

    openSave(view = false) {
      // this.$refs.save.open(view);

      this.project.pagesEntityList.forEach(ele => {
        if (ele.pagesId == this.curPage.pagesId) ele.componentEntityList = this.curPage.componentEntityList;
      });

      if (this.curPage.pagesId) {
        shopUpdate(this.curPage).then(res =>{
          if (res.code == 0) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.issave = true;

            console.log("==this.project==:",this.project);

            this.setProject(this.project);

          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });
      } else {

        pageSave(this.curPage).then(res =>{
          if (res.code == 0) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.issave = true;
            this.getProject();

            this.setProject(this.project);


          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });


      }


    },

    toSchema() {
      let { href } = this.$router.resolve({
        path: "/schema",
      });
      window.open(href);
    },

    toPagesManage() {

      console.log("this.issave:",this.issave);


      if(!this.issave){
          this.$confirm("您的修改还未保存，确定要离开此页面吗?", "", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {

          this.$router.push({ name: "pages-manage" });

            this.issave=false;


        }).catch(()=>{
        })
      }else{


        this.$router.push({ name: "pages-manage" });
        this.issave=false;

      }
    },

    viewQr() {
      this.issave = true
      this.$confirm("如不更新，则预览为上次保存的项目数据?", "是否更新保存", {
        confirmButtonText: "更新保存",
        cancelButtonText: "不更新",
        type: "warning",
      }).then(() => {
        this.openSave(true);
        this.issave = false
      }).catch(()=>{
        this.openQr()
      })
    },

    openQr() {
      this.$refs.qr.open();
    },

    // 保存
    async save() {
      let data = {
        aid: this.project.aid,
        userId: this.userInfo.userId,
        name: this.project.name,
        richText: JSON.stringify(this.project),
      };

      let { code } = await editProject(data);

      if (code == "0")
        this.$notify({
          title: "成功",
          message: "项目保存成功",
          type: "success",
        });
    },

    // 退出
    onLogout() {
      this.$confirm("是否确定退出平台?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
}
</style>
